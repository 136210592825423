var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"refund",class:{ 'refund--upgrade': _vm.isUpgrade }},[_c('div',{staticClass:"refund__inner"},[(!_vm.isUpgrade)?_c('div',{staticClass:"refund__shield"},[_c('img',{attrs:{"src":"/images/refund-shield.png","alt":"refund image"}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"refund__content-container"},[(_vm.isAddon)?_c('p',[_vm._v(_vm._s(_vm.$t('checkout.insurance.addon'))+" "+_vm._s(_vm._f("formatPrice")(_vm.refundPrice)))]):(_vm.showTitle)?_c('p',[_vm._v(_vm._s(_vm.$t('checkout.insurance.title'))+" "+_vm._s(_vm._f("formatPrice")(_vm.refundPrice)))]):(_vm.isUpgrade)?_c('p',[_vm._v(_vm._s(_vm.$t('checkout.insurance.upgrade'))+" "+_vm._s(_vm._f("formatPrice")(_vm.refundPrice)))]):_c('p',{staticClass:"refund__text"},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t('checkout.insurance.default'))+" "+_vm._s(_vm._f("formatPrice")(_vm.refundPrice))+"\n\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"refund__content"},[_c('div',{staticClass:"refund__buttons"},[_c('RadioButton',{staticClass:"refund__radio",attrs:{"data":{
							selectedValue: _vm.hasRefund,
							label: _vm.$t('yes'),
							value: true
						},"isDisabled":_vm.isShopUpdatePending},on:{"input":_vm.onSelect}}),_vm._v(" "),_c('RadioButton',{staticClass:"refund__radio",attrs:{"data":{
							selectedValue: _vm.hasRefund,
							label: _vm.$t('no'),
							value: false
						},"isDisabled":_vm.isShopUpdatePending},on:{"input":_vm.onSelect}})],1),_vm._v(" "),_c('transition',{attrs:{"type":"in-out","name":"fade"}},[(!_vm.showMoreInfo)?_c('div',{staticClass:"refund__info"},[_c('span',{on:{"click":_vm.toggleMoreInfo}},[_vm._v(_vm._s(_vm.$t('label.more_info')))])]):_vm._e()])],1)])]),_vm._v(" "),_c('transition',{attrs:{"type":"in-out","name":"fade"}},[(_vm.showMoreInfo)?_c('div',[_c('p',{staticClass:"refund__info-xcover"},[_vm._v(_vm._s(_vm.$t('checkout.insurance.xcover.body')))]),_vm._v(" "),_c('div',{staticClass:"refund__info-link",domProps:{"innerHTML":_vm._s(_vm.$t('checkout.insurance.xcover.uri'))}}),_vm._v(" "),(_vm.isInsuranceLogo)?_c('div',{staticClass:"refund__info-logo-container"},[_c('img',{staticClass:"refund__info-logo",attrs:{"src":_vm.isInsuranceLogo.fields.file.url,"alt":""}})]):_vm._e()]):_vm._e()]),_vm._v(" "),(_vm.bookingProtectCopy)?_c('p',{staticClass:"refund__warning",domProps:{"innerHTML":_vm._s(_vm.bookingProtectCopy.description)}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }